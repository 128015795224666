$(function(){
// @media screen and (min-width: 768px) と同じ
var mql = window.matchMedia('screen and (min-width: 768px)');    
var printCheck = $('.js_print').css('display') == 'block' //印刷状態をチェック



//::::::::::::::::::::::::::::::::    
// スマホとタブレットでviewportを切替え!
//::::::::::::::::::::::::::::::::

var ua = navigator.userAgent;
var getDevice = (function(){    
    if ((ua.indexOf('iPhone') > 0) || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
        //$('head').prepend('<meta name="viewport" content="width=device-width,initial-scale=1">');
        $('.txt-tellink,.txt_tellink').each(function () {
            var str = $(this).text();
            $(this).html($('<a>').attr('href', 'tel:' + str.replace(/-/g, '')).append(str + '</a>'));
        });
    } else {
        //$('head').prepend('<meta name="viewport" content="width=1240">');
    var head = $('head');
    var headChildren = head.children();
    var childrenLength = headChildren.length;
    for(var i = 0;i < childrenLength;i++){
        var metaName = headChildren.eq(i).attr('name');
        if(metaName === 'viewport'){
            headChildren.eq(i).attr('content','width=1100');
        }
      }  
//        $('.header-copy').css('font-size','10px');
//        $('.footer-link a').css('font-size','12px');
    }
})()

//:::::::::::::::::::::::::::
// これでiPadが判定できる！！
//:::::::::::::::::::::::::::
var isIPad = /iPad|Macintosh/i.test(navigator.userAgent)
&& 'ontouchend' in document
if(isIPad){
  //console.info('iPadからのアクセスです')
$('.bg-area').css('background-attachment', 'scroll');
$('.footer-contact').addClass('js_bgtab');
$('.header-copy').css('font-size','10px');
$('.footer-link a').css('font-size','12px');    
//return 'tab';    
} else {
  //console.info('iPadからのアクセスじゃないです')
}

//:::::::::::::::::::::::::::::::
// scrollTop html/bodyの判定
//:::::::::::::::::::::::::::::::
var scrollableElement;
// document.scrollingElementに対応していればそれを使う
if ('scrollingElement' in document) {
  scrollableElement = document.scrollingElement;
}
// IEのとき
else if (/*@cc_on!@*/false || (!!window.MSInputMethodContext && !!document.documentMode)) {
  scrollableElement = document.documentElement;
}
// それ以外
else {
  scrollableElement = document.body;
}    

//::::::::::::::::::::
// スマホメニューの開閉 
//::::::::::::::::::::   

var menu = $('.menu');
var menuBtn = $('.menu-btn');
var spmenu = $('.spmenu');
var body = $('body');
var link = $('a');
var state = false;
var scrollpos;
var top_btn = $('.top-btn');    
//var headerheight = $("header").outerHeight(true);

//SPmenuを開閉する関数
function SPmenuClose( btn ) {
    if( btn === undefined ){
       btn = '';
       }
    //$('.trial-modal').hide();
    if( btn ){
        if ( $(btn).hasClass('open') ){
            menuBtn.removeClass('open');
            spmenu.removeClass('menuopen');
             //console.log("OPEN!");
            //$('.owl-carousel').trigger('play.owl.autoplay');
            
                if ($(this).scrollTop() > 200) {
            top_btn.addClass('fade-btn');}
        } else {
            menuBtn.addClass('open');
            spmenu.addClass('menuopen');
            top_btn.removeClass('fade-btn');
            //$('.owl-carousel').trigger('stop.owl.autoplay');
            
        }
    } else {
        menuBtn.removeClass('open');
        spmenu.removeClass('menuopen');
    }
}

//ページ内リンクのときはSPメニューを閉じる
var SPmenuLink =  $('.spmenu a[href*="#"]'); 
$( menuBtn.add(SPmenuLink) ).on('click',function () {
    SPmenuClose( this );
})
    
//::::::::::::::::::::    
// スライドtoggle
//::::::::::::::::::::    

$(".js_st").on("click", function () {
    $(this).next().slideToggle(300, 'easeInOutQuad');
})
   

//::::::::::::::::::::::::::::::::::::    
//TOPにもどるボタン
//::::::::::::::::::::::::::::::::::::  

$(window).scroll(function () {
    if ($(this).scrollTop() > 130) {
        top_btn.addClass('fade-btn');
        $('.float-bnr').addClass('fade-btn');
        return false;
    } else {
        top_btn.removeClass('fade-btn');
        $('.float-bnr').removeClass('fade-btn');
        return false;
    }
})

//:::::::::::::::::::::::::::::    
// TOPにもどるボタンのスムーズな動き
//:::::::::::::::::::::::::::::    
var top_btnA = $('.top-btn');
top_btnA.on('click', function () {
    var speed = 500; // ミリ秒
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
if ($(target).length) {    
    var position = target.offset().top;}
    $(scrollableElement).animate({
        scrollTop: position
    }, speed, 'easeInOutQuad');
    return false;
})

//headerの高さ    
var height = 70;
if (mql.matches) {
    var height = 20;    
} 
    
//:::::::::::::::::::::::::::::    
// TOPに戻るボタンをfooterに固定
//:::::::::::::::::::::::::::::
//if (!mql.matches) {    
//$(window).scroll(function () {
//    var pageHeight = $(document).height(); 
//    var scrollPosition = $(window).height() + $(window).scrollTop(); 
//    //var footHeight = $("footer").outerHeight();
//    var footHeight = 157;
//    if (pageHeight - scrollPosition <= footHeight) {        
//        $(".top-btn").addClass('btn-fix');
//    } 
//else {
//$(".top-btn").removeClass('btn-fix');
//    }    
//})
//}
    //else{}


//::::::::::::::::::::::::::::
//  外部ページから#で始まるリンクに
//  飛んできたときの設定
//  (headerの下に潜らないように) 
//::::::::::::::::::::::::::::    
$(function () {
    var url = $(location).attr('href');
    if (url.indexOf("#") != -1) {
        var anchor = url.split("#");
        var target = $('#' + anchor[anchor.length - 1]);
        if (target.length) {
            var position = target.offset().top - height;
            $(scrollableElement).scrollTop(position);
            //return false;
        }
        //console.log(position,target,anchor)
    }
})
    
    
//::::::::::::::::::::::::::::    
//  ページ内リンクが
//  headerの下に潜らないように
//::::::::::::::::::::::::::::     

$('a[href^="#"]').click(function () {
    var anchor = $(this).attr('href'); //hrefを取得
    var anchor = anchor.split("#"); //#で分割
    var target = "#" + anchor[1]; //#の値を取得
    var offset = $(target).offset().top; //飛び先の位置を取得
    //var startPosition = $('.page-back').offset().top; //飛び先の位置を取得
    var position = offset - height;
    //console.log(startPosition);
    console.log(offset);
    $(scrollableElement).animate({
        scrollTop: position
    }, 500, 'easeInOutQuad');
    //return false;
})
 

    
//::::::::::::::    
// Slider
//::::::::::::::   
  
    
 
function checkBreakPoint(mql) {
if (mql.matches) {
//console.log('pcです');


//::::::::::::::    
// IE11対策
//::::::::::::::    
// 利用ブラウザがIE10か11か
if(navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./)) {
//$(function(){
//var style = '<link href="https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,500;0,600;1,500;1,600&family=Noto+Sans+JP:wght@400;500&family=Open+Sans:ital,wght@1,800&family=Roboto:wght@500&display=swap" rel="stylesheet">';
//    $('head link:last').after(style);
//})
  // マウスホイールでのスクロール時
  $('body').on("mousewheel", function (){
    // デフォルトの設定値をOFFに切り替える
    event.preventDefault();
    var wd = event.wheelDelta;
    var csp = window.pageYOffset;
    window.scrollTo(0, csp - wd);
  })
}
    
//:::::::::::::::::  
//  リンクを無効に
//:::::::::::::::::
 $(".js_nolinksp").on("click", function () {
    event.preventDefault();
    //return false;
})
    
//:::::::::::::::::  
//  header固定
//:::::::::::::::::
//$(window).scroll(function () {
//    if ($(this).scrollTop() > 102) {
//        $('header').addClass('fix-header');
//        return false;
//    } else {
//        $('header').removeClass('fix-header');
//        return false;
//    }
//})    
    
  
} //(mql.matches)else if end

}//(mqlここまで)

// ブレイクポイントの瞬間に発火
mql.addListener(checkBreakPoint);


//:::::::::::::::::::::::::::       
//  スクロールアニメーション
//:::::::::::::::::::::::::::

//！プリント時はスクロールアニメーションをキャンセル  

//if (!printCheck) {
//    var fadeoffset = $(window).innerHeight() * 0.3;
//    var controller = new ScrollMagic.Controller();
//if (!mql.matches) {    
//    var fadeBottom = $('.js_fadein,.gallery-item,.event-list-item:not(:first-of-type) ,.bloglist:not(:first-of-type),.bloglist-side,.workslist>div:nth-of-type(n+2)');
//}else{
//var fadeBottom = $('.js_fadein,.event-list-item:not(:first-of-type) ,.bloglist:not(:first-of-type),.workslist>div:nth-of-type(n+3)')    
//}
//    var fadTrigger = $('.js_fadein,.gallery-item');
//    for (var i = 0; i < fadeBottom.length; i++) {
//        var tagfadebtm = fadeBottom[i];
//        var tween1;
//        tween1 = TweenMax.fromTo(tagfadebtm, 0.4, {
//                y: "30px",
//                opacity: 0
//            }, // left 0、opacity 0の状態を指定
//            {
//                y: 0,
//                opacity: 1
//            } // left 100px、opacity 1まで変化させる
//        );
//        var fadebottom = new ScrollMagic.Scene({
//                triggerElement: tagfadebtm,
//                triggerHook: 'onEnter',
//                offset: fadeoffset,
//                reverse: false
//            })
//            .setTween(tween1)
//            .addTo(controller)
//        //.addIndicators({name:"fadein"});
//    }
//
////:::::::::::::::::::::::::::    
////  mimiのアニメーション
////:::::::::::::::::::::::::::   
//
//var mimielm = $('.hiraya-inlove-chara');
//var mimioffset = 0;     
//if (!mql.matches) {
//var mimioffset = "100px";    
//}     
//var tween2;     
//    tween2 = new TweenMax.to
//    (mimielm,0.4,{className:"+=anime-boundin"}); 
//    //.set(elm,{}) 
//
//    var mimichanAnime = new ScrollMagic.Scene({      
//    triggerElement:".h-hiraya-inlove",
//    triggerHook: 'onEnter',
//    offset:mimioffset,
//    reverse:false    
//  })
//.setTween(tween2)
//.addTo(controller);
//    
////:::::::::::::::::::::::::::    
////  時間差でinするアニメーション
////:::::::::::::::::::::::::::       
//var TimelineElm = $('.js_fadein-timeline>div');
//var tween3;     
//    tween3 = new TweenMax.staggerFromTo
//    (TimelineElm , 0.4 , {y: "30px",opacity:0},{y: 0,opacity: 1}, 0.2);
//    
//    var FadeinTimeline = new ScrollMagic.Scene({      
//    triggerElement:".js_fadein-timeline",
//    triggerHook: 'onEnter',
//    offset:fadeoffset,
//    reverse:false    
//  })
//.setTween(tween3)
//.addTo(controller);    
// 
////:::::::::::::::::::::::::::    
////  丸い画像のアニメーション
////:::::::::::::::::::::::::::     
//var imgElm = $('#hiraya-imgarea img');    
//var tweenImg;  
//tweenImg = new TimelineMax()
////.staggerFromTo(imgElm,1.3,{scale: 0.8,opacity:0}, 
////{delay: 0.5,ease: Elastic.easeOut.config(1, 0.3), scale: 1,opacity:1},
//////↑まではtoと同じ記法で、↓にアニメーションの時間差を指定します。
////0.3
////)
//.staggerTo(imgElm,2,{ease: Power1.easeInOut,y:"+8px",scale: 1.03,yoyo : true,repeat:-1},0.8)
////yoyo timelinemaxを使って、ふわふわした動きを追加する  
//    
//var imgAnimation = new ScrollMagic.Scene({      
//    triggerElement:"#hiraya-imgarea img",
//    triggerHook: 'onEnter',
//    reverse:false  
//  })
//.setTween(tweenImg)
//.addTo(controller);

//} //printCheck end 

checkBreakPoint(mql); // 初回チェック  
}); //$ふぁんくしょんおわり！